import Vue from 'vue'
import { DataOptions } from 'vuetify'
import IODataQuery from '@/models/IODataQuery'
import { RawLocation } from 'vue-router'

export default Vue.extend({
  props: {
    filter: {
      type: String,
      default: ''
    },
    footerProps: {
      type: [Object],
      default() {
        return {
          itemsPerPageOptions: [10, 20, 50, 100]
        }
      }
    },
    sync: {
      type: [Boolean]
    }
  },
  data() {
    return {
      searchText: this.sync ? this.$route.query.searchText as string : null,
      options: { itemsPerPage: 100 } as DataOptions
    }
  },
  watch: {
    oDataQuery(newQuery: IODataQuery, oldQuery: IODataQuery) {
      const newFilter = newQuery.$filter ?? ''
      const oldFilter = oldQuery.$filter ?? ''

      // go to page one if the filter is different
      if (newFilter !== oldFilter) {
        this.options.page = 1
      }

      // update the route if sync enabled
      if (this.sync) {
        const route = this.$route
        const newRoute: RawLocation = {
          name: route.name,
          params: route.params,
          query: { ...route.query } || {},
          hash: route.hash,
          path: route.path
        }

        // search text
        newRoute.query.searchText = this.searchText || undefined
        newRoute.query.page = this.options.page.toString() || undefined
        newRoute.query.itemsPerPage = this.options.itemsPerPage.toString() || undefined
        newRoute.query.sortBy = this.options.sortBy.join(',') || undefined
        newRoute.query.sortDesc = this.options.sortDesc.join(',') || undefined

        if (JSON.stringify(route.query) !== JSON.stringify(newRoute.query)) {
          this.$router.replace(newRoute)
        }
      }
      this.load()
    }
  },
  computed: {
    headers(): any[] {
      return []
    },
    visibleHeaders(): any[] {
      return this.headers
        .filter(x => !x.hidden)
    },
    filterString(): string {
      let filter = this.filter || ''

      if (this.searchText) {
        const searchWords = this.searchText.split(' ')
        const searchFields = this.headers
          .filter(header => header.searchable)
          .map(header => header.value.replace('.', '/')) as string[]

        const searchFilter = searchFields.map(field => {
          const fieldFilter = searchWords.map(word => `contains(cast(${field}, 'Edm.String'), '${word}')`).join(' and ')
          return `(${fieldFilter})`
        }).join(' or ')

        if (filter) {
          filter += ' and '
        }

        if (searchFilter) {
          filter += `(${searchFilter})`
        }
      }

      return filter
    },
    orderbyString(): string {
      if (!this.options?.sortBy?.length) {
        return null
      }
      const sortBy = this.options.sortBy
      const sortDesc = this.options.sortDesc

      const sortArray: string[] = []
      for (let i = 0; i < sortBy.length; i++) {
        let sortString = `${sortBy[i]} ${sortDesc[i] ? 'desc' : ''}`

        // replace . with / because odata uses / for nested properties
        sortString = sortString.replace('.', '/')
        sortString = sortString.trim()
        sortArray.push(sortString)
      }
      return sortArray.join(',')
    },
    oDataQuery(): IODataQuery {
      const options = this.options
      const $top = options.itemsPerPage
      const $skip = (options.page - 1) * $top || 0
      const $filter = this.filterString
      const $orderby = this.orderbyString

      return {
        $top,
        $skip,
        $filter,
        $orderby,
        $count: true
      }
    },
    searchPlaceholder(): string {
      return `Search: ${this.headers
        .filter(x => x.searchable)
        .map(x => x.text)
        .join(', ')}`
    }
  },
  methods: {
    load() {
    }
  },
  created() {
    if (this.sync) {
      const query = this.$route.query
      const options = this.options
      options.page = +query.page || options.page || 1
      options.itemsPerPage = +query.itemsPerPage || options.itemsPerPage || 10

      if (query.sortBy) {
        options.sortBy = (query.sortBy as string).split(',')
      }

      if (query.sortDesc !== undefined) {
        options.sortDesc = (query.sortDesc as string)
          .split(',')
          .map(x => x === 'true')
      }
    }
  }
})
