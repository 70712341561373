












import Vue from 'vue'
import ReportTable from '../components/ReportTable.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('Reports')

export default Vue.extend({
  components: {
    ReportTable
  },
  computed: {
    ...mapGetters(['report', 'error']),
    title(): string {
      let title = 'Report'
      const reportName = this.report?.name

      if (reportName) {
        title += `: ${reportName}`
      }

      return title
    }
  },
  methods: {
    ...mapActions(['exportToFile'])
  }
})
