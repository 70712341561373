





























import TableMixin from '@/mixins/TableMixin'
import { createNamespacedHelpers } from 'vuex'
import IODataQuery from '@/models/IODataQuery'
import IReportData from '../models/IReportData'
const { mapGetters, mapActions } = createNamespacedHelpers('Reports')

export default TableMixin.extend({
  data() {
    return {
      options: {
        page: 0
      }
    }
  },
  computed: {
    ...mapGetters(['reportData', 'loading']),
    headers(): any[] {
      const report = this.reportData as IReportData

      return report.columns.map((col) => {
        return {
          text: col.displayName,
          value: col.name,
          hidden: col.hidden,
          sortable: col.sortable
        }
      })
    },
    rows(): any[] {
      const report = this.$clone(this.reportData) as IReportData
      const columns = report.columns
      const rows = report.rows

      // apply format
      rows.forEach((row) => {
        for (const key in row) {
          const column = columns.find((col) => col.name === key)

          if (column && column.type === 'Date') {
            row[key] = this.$formatter.date(row[key])
          }
        }
      })

      return rows
    },
    count(): number {
      return +this.reportData['rows@odata.count']
    }
  },
  methods: {
    ...mapActions({
      getReportById: 'getReportById'
    }),
    load() {
      const id = this.$route.params.id
      const oDataQuery = this.oDataQuery
      const params: IODataQuery = {
        $select: `columns,rows($count=true;$top=${oDataQuery.$top || 0};$skip=${
          oDataQuery.$skip
        }`
      }
      if (oDataQuery.$orderby) {
        params.$select += `;$orderby=${oDataQuery.$orderby}`
      }
      params.$select += ')'
      this.getReportById({ id, params })
    }
  },
  created() {
    this.options.page = 1
  }
})
